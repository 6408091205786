
  import utilities from '@/mixins/utilities';
  import manager from '@/lib/manager';
  import Vue from 'vue';
  import { EmergencyAlertData } from '@/store/types';

  export default Vue.extend({
      props: {
          dnsName: {
              type: String,
              default: ''
          },
          clientName: {
              type: String,
              default: ''
          },
          product: {
              type: String,
              default: ''
          }
      },
      data() {
          return {
              alertDialog: false,
              snackbar: false,
              snackbarText: 'The on-call DevOps team member has been notified.',
              notes: ''
          };
      },
      mixins: [utilities],
      methods: {
          sendAlert() {
            if(!this.shouldDisplay) {
              this.snackbarText = 'Error: User does not have permission to send emergency alerts.';
              this.snackbar = true;
              return;
            }

            const alertData: EmergencyAlertData = {
                requestedBy: (localStorage.getItem('user') || 'Unknown').replace('jumpcloud_', '').replace('"', ''),
                clientName: this.clientName,
                dnsName: this.dnsName,
                notes: this.notes,
                product: this.product
            };

            manager.sendEmergencyAlert(alertData)
              .then(() => {
                  this.alertDialog = false;
                  this.snackbar = true;
              })
              .catch((error) => {
                  this.snackbarText = 'Error: ' + error + '. DevOps team member phone numbers can be found on Slack if needed.';
                  this.snackbar = true;
              });
          }
      }
  });
